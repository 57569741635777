import { IconType } from 'react-icons';
import { FaCloudUploadAlt, FaPrint } from 'react-icons/fa';
import { HiOutlineDuplicate } from 'react-icons/hi';
import { IoIosMail } from 'react-icons/io';
import { LuFileSpreadsheet } from 'react-icons/lu';
import { MdBlock } from 'react-icons/md';

interface ButtonsActionsProps {
  ariaLabel: string;
  icon: IconType;
  color: string;
  action: string;
  textTooltip: string;
}

export const getButtonActions = (
  tipo_status: number,
): ButtonsActionsProps[] => {
  return [
    {
      ariaLabel: 'imprimir venda',
      icon: FaPrint,
      color: '#585858',
      action: 'Imprimir',
      textTooltip: 'Comprovante',
    },
    {
      ariaLabel: 'duplicar venda',
      icon: HiOutlineDuplicate,
      color: '#FD9A19',
      action: 'Duplicar',
      textTooltip: 'Duplicar',
    },
    {
      ariaLabel: 'enviar venda',
      icon: IoIosMail,
      color: '#61B554',
      action: 'Enviar',
      textTooltip: 'Enviar',
    },
    {
      ariaLabel: 'cencelar venda',
      icon: MdBlock,
      color: '#CF2A31',
      action: 'Cancelar',
      textTooltip: 'Cancelar',
    },
    {
      ariaLabel: tipo_status === 0 ? 'Imprimir Danfe NFCe' : 'Transmitir Venda',
      icon: tipo_status === 0 ? LuFileSpreadsheet : FaCloudUploadAlt,
      color: '#297BE4',
      action: tipo_status === 0 ? 'ImprimirDanfeNFCe' : 'Transmitir',
      textTooltip: tipo_status === 0 ? 'Imprimir Danfe NFCe' : 'Transmitir',
    },
  ];
};
