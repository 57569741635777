import jsPDF from 'jspdf';
import React, { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { InputMoney } from '~/components/NovosInputs/InputMoney2';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { aberturaPdv } from '~/pages/PdvOnline/services/abertura-pdv';
import { InitialStatePdv } from '~/pages/PdvOnline/types/context';
import { codTipoMov } from '~/pages/PdvOnline/utils/codTipoMov';
import { tableHeaderCell } from '~/pages/PdvOnline/utils/tableHeaderCell';
import ImpressaoPdvOnlie from '~/utils/classes/ImpressaoRecibo/PdvOnline';
import { ReceiptData } from '~/utils/classes/ImpressaoRecibo/PdvOnline/receipt.data';
import { Modal } from '..';
import {
  Button,
  ModalContent,
  Table,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  ValueContainer,
} from './styles';

const MySwal = withReactContent(Swal);

export const ModalAbertura: React.FC = () => {
  const {
    toggleStatusPdv,
    isPdvOpen,
    handleInitialStatePdv,
    initialStatePdv,
    isModalOpen,
    onOpenModal,
    onCloseModal,
  } = usePdvOnline();

  const modalContentRef = useRef<HTMLDivElement | null>(null);
  const buttonAbrirRef = useRef<HTMLButtonElement | null>(null);

  const {
    register,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const valAbertura = watch('val_abertura');

  const modalOpen =
    !isPdvOpen && isModalOpen.isOpen && isModalOpen.modal === 'aberturaPdv';

  const gerarEAbrirPDF = (receipt: string, imprimir = false) => {
    const receiptInText = receipt;
    const linhas = receiptInText.split('\n');
    const numeroDeLinhas = linhas.length;

    const espacamentoEntreLinhas = 5; // Ajuste este valor conforme necessário
    const alturaPagina = numeroDeLinhas * espacamentoEntreLinhas + 20; // + 20mm de margem
    const larguraPagina = 80;

    // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      // format: [4 * 25.4, 6 * 25.4], // Aproximadamente 4x6 polegadas
      format: [larguraPagina, alturaPagina],
    });

    doc.setFont('Courier'); // Define a fonte para Courier
    doc.setFontSize(8); // Define o tamanho da fonte
    doc.setTextColor(0, 0, 0); // Define a cor do texto para preto

    linhas.forEach((linha, index) => {
      doc.text(linha, 0, 10 + index * espacamentoEntreLinhas);
    });

    const pdfData = doc.output();
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  };
  const handleAbrir = useCallback(async () => {
    if (!initialStatePdv) return;

    let openPdv = false;

    const initialState: InitialStatePdv = {
      ...initialStatePdv,
      status_pdv: 'ABERTO',
      val_saldo: valAbertura === '' ? '0,00' : valAbertura,
    };

    const anotherOp = await aberturaPdv.checkCaixaOutroOperador(
      initialStatePdv.num_pdv,
      initialStatePdv.cod_loja,
      Number(initialStatePdv.cod_operador),
    );

    if (anotherOp) {
      MySwal.fire({
        title: 'Caixa está aberto com outro operador.',
        text: 'Aguarde o fechamento para prosseguir.',
        icon: 'warning',
      });
      toggleStatusPdv(false);
      onOpenModal('operadorCaixa');
      return;
    }

    const statusCaixa = await aberturaPdv.checkCaixaFechadoAdequadamente(
      initialStatePdv.num_pdv,
      initialStatePdv.cod_loja,
    );

    if (statusCaixa.status) {
      await MySwal.fire({
        title: 'Aviso',
        html: `
            <span>
              <p>Caixa não foi fechado pelo operador ${statusCaixa.cod_operador} no dia ${statusCaixa.dta_movimento}.</p>
              <p>Fechamento será lançado automaticamente.</p>
            </span>
          `,
        icon: 'warning',
      }).then(async (res) => {
        if (!res.isConfirmed) return;

        const wasInserted = await aberturaPdv.insereMovimento(
          initialStatePdv.num_pdv,
          initialStatePdv.cod_loja,
          {
            cod_operador: Number(statusCaixa.cod_operador),
            cod_tipo_mov: codTipoMov.FECHAMENTO,
          },
        );

        if (!wasInserted) return;

        const resposta = await MySwal.fire({
          title: 'Sucesso',
          text: `Fechamento do dia ${statusCaixa.dta_movimento} executado com sucesso!`,
          icon: 'success',
        }).then(async (resp) => {
          if (resp.isConfirmed) return true;
          return false;
        });

        if (resposta) openPdv = true;
        else openPdv = false;
      });
    }

    if (!valAbertura || valAbertura === '' || valAbertura === '0,00') {
      const continua = await MySwal.fire({
        title: 'Valor de abertura não foi informado.',
        text: 'Deseja continuar mesmo assim?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#0065FF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (response) => {
        if (response.isConfirmed) return true;
        return false;
      });

      if (!continua) return;
    }

    if (
      (valAbertura || valAbertura !== '' || valAbertura !== '0,00') &&
      !openPdv
    )
      openPdv = true;

    const movimento = aberturaPdv.insereMovimento(
      initialStatePdv.num_pdv,
      initialStatePdv.cod_loja,
      {
        cod_operador: initialStatePdv.cod_operador,
        cod_tipo_mov: codTipoMov.ABERTURA,
        val_mov: valAbertura,
      },
    );

    const tesouraria = aberturaPdv.tesourariaStatus(
      initialStatePdv.num_pdv,
      initialStatePdv.cod_loja,
    );

    await Promise.all([movimento, tesouraria]);

    try {
      const cupom = await aberturaPdv.getLojaCupomAbertura(
        Number(initialStatePdv.cod_loja),
        Number(initialStatePdv.cod_operador),
        Number(initialStatePdv.num_pdv),
      );

      if (typeof cupom === 'boolean') return;

      const dataCupom: ReceiptData = {
        ...cupom,
        val_mov: valAbertura || '0,00',
      };

      const impressaoReciboAbertura = new ImpressaoPdvOnlie(dataCupom);
      const reciboGerado = await impressaoReciboAbertura.baixarRecibo();

      gerarEAbrirPDF(reciboGerado);

      handleInitialStatePdv(initialState);
      toggleStatusPdv(openPdv);
      onCloseModal();
      reset({ val_abertura: '0,00' });
    } catch (error) {
      return toast.warning(
        'Loja não encontrada ou inativa. Por favor, entre em contato com o suporte',
      );
    }
  }, [
    handleInitialStatePdv,
    onOpenModal,
    onCloseModal,
    initialStatePdv,
    toggleStatusPdv,
    valAbertura,
    reset,
  ]);

  useEffect(() => {
    if (modalOpen && modalContentRef.current) {
      const inputElement: HTMLInputElement | null =
        modalContentRef.current.querySelector('[name="val_abertura"]');
      if (inputElement) {
        inputElement.focus();
        inputElement.value = '';
      }
    }
  }, [modalOpen]);

  useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent) => {
      if (buttonAbrirRef.current && ev.key === 'Enter')
        buttonAbrirRef.current.click();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  if (!initialStatePdv) return <></>;

  const loja = `${initialStatePdv.des_loja}`;

  return (
    <Modal isOpen={modalOpen} title="Valor de Abertura">
      <ModalContent ref={modalContentRef}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaderCell.slice(0, 3).map(({ cellName }) => (
                <TableHeaderCell key={cellName}>{cellName}</TableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <tbody>
            <TableRow>
              <TableCell>{loja}</TableCell>
              <TableCell>{initialStatePdv.des_operador}</TableCell>
              <TableCell>{initialStatePdv.num_pdv}</TableCell>
            </TableRow>
          </tbody>
        </Table>
        <ValueContainer>
          <InputMoney
            label="Val. Abertura"
            placeholder="0,00"
            maxLength={10}
            min={0}
            decimals={2}
            name="val_abertura"
            register={register}
            control={control}
            isError={!!errors.val_abertura}
          />

          <Button
            onClick={() => onOpenModal('operadorCaixa')}
            type="button"
            style={{ backgroundColor: '#FF7F27' }}
          >
            Voltar
          </Button>
          <Button ref={buttonAbrirRef} onClick={handleAbrir} type="button">
            Abrir
          </Button>
        </ValueContainer>
      </ModalContent>
    </Modal>
  );
};
