import styled from 'styled-components';

export const BuscaVendaContainer = styled.section`
  display: flex;
  flex-direction: column;
  /* width: 49.375rem; */

  .buttonOk {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.75rem;

    .status-nfce {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: large;
        color: black;
        margin-top: 8px;
      }
    }
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0.75rem;

  .parceiro {
    width: 100%;
  }

  .inputsContainer {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    width: 100%;

    .periodoContent {
      display: flex;
      align-items: center;
      gap: 0.625rem;
    }

    .buttonContainer {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;

  padding: 0.5rem;
  width: 6.875rem;
  border-radius: 0.3125rem;
  margin-top: 1.6875rem;
  gap: 0.3125rem;

  color: white;

  &:disabled {
    opacity: 60%;
  }

  &:hover {
    opacity: 85%;

    &:disabled {
      opacity: 60%;
    }
  }
`;

export const ButtonFiltrar = styled(Button)`
  background-color: #9a01fd;
`;

export const ButtonReset = styled(Button)`
  background-color: #2a79e3;
`;

export const ButtonOk = styled.button`
  font-weight: 600;
  font-size: medium;
  border: none;
  padding: 0.5rem;
  border-radius: 0.4375rem;
  width: 6.875rem;

  background-color: #039e11;
  color: white;

  &:disabled {
    opacity: 60%;
  }

  &:hover {
    opacity: 85%;
  }
`;
